import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface Props {
  jobs: Job[] | undefined;
}

const JobListTable = ({ jobs }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="job list table">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Video Path</TableCell>
            <TableCell>Protocol Path</TableCell>
            <TableCell>Team Left</TableCell>
            <TableCell>Team Right</TableCell>
            <TableCell>League</TableCell>
            <TableCell>Break Start Time</TableCell>
            <TableCell>Break Finish Time</TableCell>
            <TableCell>Report Path</TableCell>
          </TableRow>
        </TableHead>
        {jobs ? (
          <TableBody>
            {jobs.map((job, index) => (
              <TableRow key={index}>
                <TableCell>{job.status.name}</TableCell>
                <TableCell>{job.job_data.video_path}</TableCell>
                <TableCell>{job.job_data.protocol_path}</TableCell>
                <TableCell>{job.job_data.team_left}</TableCell>
                <TableCell>{job.job_data.team_right}</TableCell>
                <TableCell>{job.job_data.league}</TableCell>
                <TableCell>
                  {job.job_data.break_start_time?.toString()}
                </TableCell>
                <TableCell>
                  {job.job_data.break_finish_time?.toString()}
                </TableCell>
                <TableCell>{job.report_path || "N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody />
        )}
      </Table>
    </TableContainer>
  );
};

export default JobListTable;
