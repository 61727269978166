import React from "react";
import axios from "../constants/axiosUserAPIv1";
import APIv1 from "../constants/urls";
import { useQuery } from "@tanstack/react-query";
import QUERY_KEYS from "../constants/queryKeys";
import { Box } from "@mui/material";
import JobListTable from "./JobListTable";

const JobList = () => {
  async function fetchJobList() {
    try {
      const response = await axios.get<Job[]>(APIv1.endpoints.jobList);
      return response.data;
    } catch (e) {
      alert(e);
    }
  }

  const { data: jobList } = useQuery({
    queryKey: QUERY_KEYS.jobList,
    queryFn: fetchJobList,
  });
  return (
    <Box>
      <JobListTable jobs={jobList} />
    </Box>
  );
};

export default JobList;
