import React from "react";

const ErrorPage = () => {
  return (
    <div>
      <h1 style={{ color: "red" }}>Вы перешли на несуществующую страницу!</h1>
    </div>
  );
};

export default ErrorPage;
