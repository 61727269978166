import React, { Dispatch, SetStateAction, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import axios from "../../../constants/axiosUserAPIv1";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { AxiosProgressEvent, isCancel as axiosIsCancel } from "axios";
import { MuiFileInput } from "mui-file-input";
import APIv1 from "../../../constants/urls";

const directUploadStart = (
  startAPIEndpoint: string,
  fileName: string,
  fileType: string,
  path: string | undefined,
) => {
  return axios.post(startAPIEndpoint, {
    file_name: fileName,
    file_type: fileType,
    path: path,
  });
};

const directUploadDo = (
  url: string,
  file_path: string,
  file: File,
  setUploadProgress: Dispatch<SetStateAction<number>>,
  controller: AbortController,
) => {
  const postData = new FormData();

  postData.append("file", file);

  return axios
    .put(url, postData, {
      signal: controller.signal,
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total,
          );
          setUploadProgress(progress);
        }
      },
    })
    .then(() => Promise.resolve({ file_path: file_path }));
};

const directUploadFinish = () => {
  // pass
};

interface S3Part {
  ETag: string;
  PartNumber: number;
}

const multipartUploadDo = async (
  file: File,
  numChunks: number,
  chunkSize: number,
  key: string,
  uploadId: string,
  controller: AbortController,
  setUploadProgress: Dispatch<SetStateAction<number>>,
) => {
  const parts: S3Part[] = [];

  for (let partNumber = 1; partNumber <= numChunks; partNumber++) {
    const start = (partNumber - 1) * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const fileChunk = file.slice(start, end);

    const presignedUrlResponse = await axios.post(
      APIv1.endpoints.multipartUpload.presignedUrl,
      {
        key: key,
        upload_id: uploadId,
        part_number: partNumber,
      },
    );

    const presignedUrl = presignedUrlResponse.data.url;
    const uploadResponse = await axios.put(presignedUrl, fileChunk, {
      signal: controller.signal,
      headers: { "Content-Type": file.type },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round(
            (100 * (start + progressEvent.loaded)) / file.size,
          );
          setUploadProgress(progress);
        }
      },
    });

    parts.push({
      ETag: uploadResponse.headers.etag,
      PartNumber: partNumber,
    });
  }

  return parts;
};

const directUpload = async (
  file: File,
  uploadPath: string,
  setUploadProgress: Dispatch<SetStateAction<number>>,
  controller: AbortController,
) => {
  const response = await directUploadStart(
    APIv1.endpoints.directUpload.start,
    file.name,
    file.type,
    uploadPath,
  );

  const file_path = response.data.file_path;
  const presigned_url = response.data.url;

  await directUploadDo(
    presigned_url,
    file_path,
    file,
    setUploadProgress,
    controller,
  );

  directUploadFinish();

  return file_path;
};

const multipartUpload = async (
  file: File,
  uploadPath: string,
  setUploadProgress: Dispatch<SetStateAction<number>>,
  controller: AbortController,
) => {
  const initResponse = await axios.post(APIv1.endpoints.multipartUpload.start, {
    file_name: file.name,
    file_type: file.type,
    path: uploadPath,
  });

  const uploadId = initResponse.data.upload_id;
  const file_path = initResponse.data.file_path;
  const chunkSize = 64 * 1024 * 1024; // 64MB per chunk
  const numChunks = Math.ceil(file.size / chunkSize);

  const parts = await multipartUploadDo(
    file,
    numChunks,
    chunkSize,
    file_path,
    uploadId,
    controller,
    setUploadProgress,
  );

  // Complete multipart upload
  await axios.post(APIv1.endpoints.multipartUpload.finish, {
    key: file_path,
    upload_id: uploadId,
    parts: parts,
  });
  return file_path;
};

interface Props {
  placeholder: string;
  setPath: Dispatch<SetStateAction<JobData>>;
  name: string;
  accept?: string;
  error?: boolean;
  helperText?: string;
  uploadPath?: string;
}

const S3FileUpload = ({
  placeholder,
  setPath,
  name,
  accept,
  error,
  helperText,
  uploadPath = "",
}: Props) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [uploadError, setUploadError] = useState(false);

  const handleFileChange = async (newFile: File | null) => {
    if (!newFile) return;

    setUploadError(false);
    setFile(newFile);
    const controller = new AbortController(); // Create a new AbortController
    setAbortController(controller);

    try {
      setIsUploading(true);

      // 100 mb
      if (newFile.size < 100 * 1024 * 1024) {
        // Direct upload

        const file_path = await directUpload(
          newFile,
          uploadPath,
          setUploadProgress,
          controller,
        );

        setPath((curr) => ({ ...curr, [name]: file_path }));
      } else {
        // Multipart upload

        const file_path = await multipartUpload(
          newFile,
          uploadPath,
          setUploadProgress,
          controller,
        );
        setPath((curr) => ({ ...curr, [name]: file_path }));
      }
    } catch (e) {
      console.log(e);
      if (axiosIsCancel(e)) {
        // console.log("Upload canceled");
      } else {
        setUploadError(true);
        // alert(e);
      }
    } finally {
      setIsUploading(false);
      setAbortController(null);
    }
  };

  const handleCancelUpload = () => {
    abortController?.abort();
  };

  return (
    <Stack>
      <MuiFileInput
        id={placeholder}
        onChange={handleFileChange}
        placeholder={placeholder}
        variant="outlined"
        value={file}
        disabled={isUploading}
        error={error}
        helperText={error && helperText}
        InputProps={{
          inputProps: {
            accept: accept,
          },
          startAdornment: <AttachFileIcon />,
        }}
        clearIconButtonProps={{
          disabled: true,
        }}
        onClick={(e) => {
          (e.target as HTMLInputElement).value = "";
        }}
      />
      {isUploading && (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} mr={1}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" color="textSecondary" align="center">
              {`${uploadProgress}%`}
            </Typography>
          </Box>
          <IconButton color="default" onClick={handleCancelUpload}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {uploadError && (
        <Box display="flex" alignItems="center" color="error.main">
          <ErrorOutlineIcon />
          <Typography variant="body2" color="error" align="center" ml={1}>
            Failed to upload file. Please try again.
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default S3FileUpload;
