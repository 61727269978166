import React, { ChangeEvent, useState } from "react";
import axios from "../constants/axiosUserAPIv1";
import APIv1 from "../constants/urls";
import { Box, Button, Stack, TextField } from "@mui/material";
import { TimeField } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import S3FileUpload from "../components/UI/Form/S3FileUpload";

const FormVid = () => {
  const initFormData = {
    team_left: "",
    team_right: "",
    league: "",
    break_start_time: null,
    break_finish_time: null,
    protocol_path: "",
    video_path: "",
  };

  const [formData, setFormData] = useState<JobData>(initFormData);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  async function postJob() {
    try {
      return await axios.post<JobData>(APIv1.endpoints.jobPost, {
        ...formData,
        break_start_time: formData.break_start_time?.format("HH:mm:ss"),
        break_finish_time: formData.break_finish_time?.format("HH:mm:ss"),
      });
    } catch (e) {
      alert(e);
    }
  }

  const isFormDataValid = (formData: JobData): boolean => {
    for (const key in formData) {
      if (
        formData[key as keyof JobData] === "" ||
        formData[key as keyof JobData] === null
      ) {
        return false;
      }
    }
    return !!(
      formData.break_start_time?.isValid() &&
      formData.break_finish_time?.isValid()
    );
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitClicked(true);
    if (isFormDataValid(formData)) {
      const response = await postJob();
      if (!response) return;

      if (response.status === 201) {
        window.location.reload();
      } else if (response.status === 401) {
        alert("Unvalid input");
      } else {
        alert();
      }
    }
  };

  return (
    <Box>
      <Stack
        component="form"
        sx={{
          width: "35ch",
        }}
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          id="team_left"
          name="team_left"
          label="Команда слева"
          variant="outlined"
          onChange={handleChangeText}
          error={formData.team_left === "" && submitClicked}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          required
        />
        <TextField
          id="team_right"
          name="team_right"
          label="Команда справа"
          variant="outlined"
          onChange={handleChangeText}
          error={formData.team_right === "" && submitClicked}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          required
        />
        <TextField
          id="league"
          name="league"
          label="Лига"
          variant="outlined"
          onChange={handleChangeText}
          error={formData.league === "" && submitClicked}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          required
        />
        <TimeField
          id="break_start_time"
          name="break_start_time"
          label="Время начала перерыва"
          format="HH:mm:ss"
          variant="outlined"
          onChange={(value: Dayjs | null) => {
            setFormData({
              ...formData,
              break_start_time: value,
            });
          }}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          required
          slotProps={{
            textField: {
              error: !formData.break_start_time?.isValid() && submitClicked,
            },
          }}
        />
        <TimeField
          id="break_finish_time"
          name="break_finish_time"
          label="Время конца перерыва"
          format="HH:mm:ss"
          variant="outlined"
          onChange={(value: Dayjs | null) => {
            setFormData({
              ...formData,
              break_finish_time: value,
            });
          }}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          required
          slotProps={{
            textField: {
              error: !formData.break_finish_time?.isValid() && submitClicked,
            },
          }}
        />
        <S3FileUpload
          placeholder="Видео матча*"
          name="video_path"
          setPath={setFormData}
          accept="video/*"
          error={formData.video_path === "" && submitClicked}
          helperText={"Загрузите видео матча"}
          uploadPath={"videos/"}
        />
        <S3FileUpload
          placeholder="Протокол матча*"
          name="protocol_path"
          setPath={setFormData}
          accept=".xlsx, .xls, .csv"
          error={formData.protocol_path === "" && submitClicked}
          helperText={"Загрузите протокол матча "}
          uploadPath={"protocols/"}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

export default FormVid;
