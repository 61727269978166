const APIv1 = {
  baseURL: process.env.REACT_APP_API_URLV1,
  endpoints: {
    jobPost: "jobPost/",
    directUpload: {
      start: "directUpload/start/",
      finish: "directUpload/finish/",
    },
    multipartUpload: {
      start: "multipartUpload/start/",
      presignedUrl: "multipartUpload/presignedUrl/",
      finish: "multipartUpload/finish/",
    },
    jobList: "jobList/",
  },
};

export default APIv1;
