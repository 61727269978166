import React from "react";
import JobForm from "./JobForm";
import { Box } from "@mui/material";
import JobList from "./JobList";

const MainPage = () => {
  return (
    <Box p={9} display="flex" justifyContent="space-between" gap={3}>
      <Box flex={1}>
        <JobForm />
      </Box>
      <Box flex={2}>
        <JobList />
      </Box>
    </Box>
  );
};

export default MainPage;
